const constants = {
  ADDITION_DURATION_CONV: 3,
  DEFAULT_PAGE_SIZE: 20,
  DEFAULT_PAGE_SIZE_SETTINGS_LISTS: 10, // smaller page size for lists that are in intents settings
  HOME_PAGE_APPS_NUMBER: 10,
  COLORS: {
    PRIMARY: 'primary',
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
    ACCENT: 'accent',
    BRAND: 'brand',
    METAL: 'metal',
    FOCUS: 'focus',
    INFO: 'info',
    SECONDARY: 'secondary',
    BLACK50: 'muted'
  },
  INTENT_FILTERS: {
    ALL: 'intent_filters_all',
    NOT_UNDERSTOOD: 'intent_filters_not_understood',
    SPECIFIC_INTENT: 'intent_filters_specific_intent'
  },
  KB_FILTERS: {
    ACTIVATED: 'activated',
    DEACTIVATED: 'deactivated'
  },
  INTENT_UNKNOWN: 'intent_unknown',
  PLATFORMS_AVAILABLE_LOCALES: [
    'fr_FR',
    'en_US',
    'fr_CA',
    'en_GB',
    'es_ES',
    'zh_HK',
    'zh_CN',
    'zh_TW',
    'pt_PT',
    'de_DE'
  ],
  INTERFACE_AVAILABLE_LOCALES: ['fr_FR', 'en_US'],
  DEFAULT_PLATFORMS_LOCALE: 'fr_FR',
  DEFAULT_INTERFACE_LOCALE: 'en_US',
  DEFAULT_VOICE: 'Wavenet-A',
  UNKNOWN_LOCALE: 'na_NA',
  ALL_LOCALE: 'all',
  PAGE_SIZES: [10, 20, 30, 50, 100],
  CONFIGURABLE_PLATFORMS: ['website', 'yelda', 'alexa', 'demo', 'phone-twilio', 'kiosk', 'google-chat', 'whatsapp'],
  CONFIGURABLE_BY_YELDA_PLATFORMS: ['messenger', 'instagram', 'workplace', 'whatsapp'],
  DEFAULT_ACTIVATED_PLATFORMS: ['kiosk', 'google-chat'],
  PLATFORM_TYPE: {
    ALL: 'all',
    messenger: 'messenger',
    instagram: 'instagram',
    workplace: 'workplace',
    twitter: 'twitter',
    alexa: 'alexa',
    website: 'website',
    'website-desktop': 'website-desktop',
    'website-mobile': 'website-mobile',
    whatsapp: 'whatsapp',
    sms: 'sms',
    'phone-twilio': 'phone-twilio',
    kiosk: 'kiosk',
    'google-chat': 'google-chat'
  },
  PLATFORMS: {
    all: {
      name: 'all',
      icon: '',
      link: ''
    },
    alexa: {
      name: 'alexa',
      icon: 'fab fa-amazon',
      fileName: 'alexa_logo.svg',
      category: 'voice',
      useVoiceAnswerType: true,
      shouldShowSaveButton: true
    },
    'phone-twilio': {
      name: 'phone-twilio',
      icon: 'fas fa-phone',
      filename: '',
      category: 'voice',
      isPhone: true,
      shouldShowSaveButton: true
    },
    kiosk: {
      name: 'kiosk',
      icon: 'fas fa-desktop',
      filename: '',
      category: 'voice',
      useVoiceAnswerType: true,
      shouldShowSaveButton: false
    },
    website: {
      name: 'website',
      icon: 'fas fa-laptop',
      fileName: 'website_logo.svg',
      category: 'chat',
      useVoiceAnswerType: true,
      shouldShowSaveButton: true
    },
    'website-desktop': {
      name: 'website_desktop',
      icon: 'fas fa-laptop',
      fileName: 'website_logo.svg'
    },
    'website-mobile': {
      name: 'website_mobile',
      icon: 'fas fa-laptop',
      fileName: 'website_logo.svg'
    },
    yelda: {
      name: 'yelda',
      icon: '',
      fileName: '',
      category: 'chat',
      useVoiceAnswerType: true,
      shouldShowSaveButton: true
    },
    demo: {
      name: 'demo',
      icon: '',
      fileName: '',
      category: 'chat',
      useVoiceAnswerType: true,
      shouldShowSaveButton: true
    },
    'google-chat': {
      name: 'google-chat',
      icon: 'fab fa-google',
      fileName: 'google_chat_logo.svg',
      category: 'chat',
      urls: {
        consoleUrl: 'https://console.cloud.google.com/?hl=fr',
        gsuiteUrl: 'https://workspace.google.com/intl/fr/products/gmail/',
        chatUrl: 'https://mail.google.com/chat/u/0/#chat/',
        publishUrl: 'https://developers.google.com/workspace/marketplace/how-to-publish'
      },
      shouldShowSaveButton: false
    },
    messenger: {
      name: 'messenger',
      icon: 'fab fa-facebook-messenger',
      fileName: 'messenger_logo.svg',
      category: 'chat',
      shouldShowSaveButton: false
    },
    instagram: {
      name: 'instagram',
      icon: 'fab fa-instagram',
      fileName: 'instagram_logo.svg',
      category: 'chat'
    },
    workplace: {
      name: 'workplace',
      icon: '',
      fileName: 'workplace_logo.svg',
      category: 'chat',
      shouldShowSaveButton: false
    },
    sms: {
      name: 'sms',
      icon: 'fas fa-phone',
      fileName: '',
      category: 'chat',
      shouldShowSaveButton: false
    },
    whatsapp: {
      name: 'whatsapp',
      icon: 'fab fa-whatsapp',
      fileName: 'whatsapp_logo.svg',
      category: 'chat',
      shouldShowSaveButton: false
    }
  },
  PREVIEW_INTENTS_NUMBER: 3,
  PREVIEW_MESSAGE_SIZE: 50,
  SENDER_TYPE: {
    BOT: 0,
    USER: 1
  },
  LOGIN: {
    PASSWORD_MIN_LENGTH: 5
  },
  WARNING_MESSAGE_DELAY: 3000,
  ALERT_MESSAGE_DELAY: 5000,
  REDIRECTION_DELAY: 3000,
  MAX_CSV_FILE_SIZE: 2097152, // 2 Mo
  DEFAULT_ASSISTANT_STATUS: {
    ASSIGNED: 'assigned',
    UNASSIGNED: 'unassigned'
  },
  CONVERSATION: {
    BADGE_BIG: 14,
    BADGE_MEDIUM: 10,
    BADGE_SMALL: 6
  },
  REGEX: {
    HEX_COLOR: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
    SLUG: {
      ALPHA_NUMERIC: /^[a-z0-9][a-z0-9_]{1,62}[a-z0-9]$/,
      ALPHA_ONLY: /^[a-z][a-z_]{1,62}[a-z]$/
    },
    OBJECT_ID: /^[a-f0-9]{24}$/,
    // Contains at least 1 letter
    MIN_ONE_LETTER: /[a-zA-Z]+/
  },
  DEBOUNCE_DELAY: 500,
  DEBOUNCE_DELAY_EDITABLE_TEXT: 2000,
  SEARCH_MIN_LENGTH: 3,
  SLUG_MIN_LENGTH: 3,
  SLUG_DEFAULT_MAX_LENGTH: 64,
  DASHBOARD_PAGE: 'dashboard',
  REDIRECT_TO_DASHBOARD_PAGES: ['apps_list', 'assistant_creation'],
  VOICES_TEST_PHRASES: {
    en_US: "Hi, I'm the voice app of your company",
    en_GB: "Hi, I'm the voice app of your company",
    fr_FR: "Bonjour, je suis l'application vocale de votre entreprise",
    fr_CA: "Bonjour, je suis l'application vocale de votre entreprise",
    es_ES: "Hi, I'm the voice app of your company",
    zh_HK: '嗨，我是您公司的语音应用',
    zh_CN: '嗨，我是您公司的语音应用',
    zh_TW: '嗨，我是您公司的語音應用',
    de_DE: 'Hallo, ich bin die Sprach-App Ihres Unternehmens',
    pt_PT: 'Olá, sou o app de voz da sua empresa'
  },
  NEW_SLUG: 'new',
  INTENT_TABS: {
    intents: 'intents',
    entities: 'entities',
    nlpWordRules: 'nlpWordRules',
    nlpsettings: 'nlpSettings'
  },
  ACTION_TABS: {
    ACTION_LIST: 'list',
    WELCOME_ACTION: 'welcome',
    FALLBACK_ACTION: 'fallback',
    PUSH_NOTIFICATIONS: 'pushNotifications',
    CONDITIONS: 'conditions',
    SLOTS: 'slots',
    METADATA: 'metadata',
    FOLLOWUP_ACTION: 'followup',
    LINKING_SENTENCES: 'linkingSentences'
  },
  FOLLOWUP_TABS: {
    FLOW: 'flow',
    SETTINGS: 'settings'
  },
  DEFAULT_VALUE: 'default',
  WEBCHAT_TITLE_MAX_LENGTH: 40,
  BUBBLE_TEXT_MAX_LENGTH: 100,
  URL_MAX_LENGTH: 50,
  INPUT_TYPES: {
    KEYBOARD: 'keyboard',
    TOUCH: 'touch',
    VOICE: 'voice',
    KEYPAD: 'keypad'
  },
  PROD_ENV_NAME: 'production',
  YELDA_LOGO_PATH: 'assets/ylogo.png',
  YELDA_WHITE_LOGO_PATH: 'assets/logo-square-white.png',
  ASSISTANT_DISPLAY_ID_LENGTH: 5,
  LIVE_CHAT_DISABLED_INPUT_DELAY: 1500,

  NOTIFICATION_SOUND: 'https://yelda-chat.s3.eu-west-3.amazonaws.com/sounds/notification.mp3',
  PLACEHOLDER_FOR_MEDIA_UPLOAD: 'USER_MEDIA',
  UNIVERSAL_TIMEZONE: 'UTC',
  TRIBUTE_TAG_OPTIONS: {
    trigger: '[',
    lookup: 'value',
    values: [],
    requireLeadingSpace: true,
    autocompleteMode: false
  },
  NO_DRAG_OPTIONS: {
    filter: '.no-draggable',
    preventOnFilter: false
  },
  ASSISTANT_ELEMENT_STATUS: {
    BUILT_IN: 'built_in',
    CUSTOM: 'custom'
  },
  DEFAULT_INPUT_MAX_LENGTH: 50,
  ONE_MINUTE_IN_MILLISECONDS: 60 * 1000,
  ENDPOINT_TIMEOUT_VALUES: {
    MIN: 1,
    DEFAULT: 5,
    MAX: 120
  },
  LIVE_CHAT_DEFAULT_SESSION_TIMEOUT_DELAY: 24 * 3600, // 24 hrs in seconds
  DATA_TYPES: {
    NUMBER: 'number',
    STRING: 'string',
    BOOLEAN: 'boolean'
  },
  DROPDOWN: {
    DEFAULT_FILTER: 'formattedName',
    CONDITIONS: { CUSTOM_KEY: 'assistantId', BUILTIN_HAS_KEY: false },
    SLOTS: { CUSTOM_KEY: 'assistantId', BUILTIN_HAS_KEY: false },
    INTENTS: { CUSTOM_KEY: 'intentMasterId', BUILTIN_HAS_KEY: true },
    ACTIONS: { CUSTOM_KEY: 'masterActionId', BUILTIN_HAS_KEY: true },
    ENTITIES: { CUSTOM_KEY: 'assistantId', BUILTIN_HAS_KEY: false },
    METADATA: { CUSTOM_KEY: 'assistantId', BUILTIN_HAS_KEY: false },
    DEFAULT_TYPE_BADGES_VISIBILITY: true
  },
  ENDPOINT_PARAM_NAME_LENGTH_LIMIT: {
    MIN: 1,
    MAX: 100
  }
}

constants.liveChatPlatformTypes = [
  constants.PLATFORMS.website.name,
  constants.PLATFORMS.messenger.name,
  constants.PLATFORMS.whatsapp.name,
  constants.PLATFORMS.instagram.name
]

constants.phoneNumberAvailablePlatformTypes = [
  constants.PLATFORMS['phone-twilio'].name,
  constants.PLATFORMS.whatsapp.name
]

export default constants
