const nlpConfig = {
  DIALOGFLOW_AGENT_BASE_URL: 'https://dialogflow.cloud.google.com/#/agent',
  NLP_SOLUTIONS: {
    DIALOGFLOW: 'dialogflow',
    ALEXA: 'alexa',
    LUIS: 'luis'
  },
  TRAINING_WAITING_TIME: 30000,
  STT_SERVICES: {
    AZURE: 'azure',
    DIALOGFLOW: 'dialogflow',
    DEEPTRANSCRIPT: 'deeptranscript',
    DEEPGRAM: 'deepgram',
    GLADIA: 'gladia',
    SPEECHMATICS: 'speechmatics',
    ALLO_MEDIA: 'allo-media'
  },
  ALLO_MEDIA_SETTINGS: {
    GRAMMARS: {
      ALTERNATIVES: 'builtin:speech/keywords?alternatives=', // + mutually exclusive keywords separated by |
      // The Yes or No grammar. This grammar is closed, and is therefore compatible with Hotword mode.
      BOOLEAN: 'builtin:speech/boolean',
      DATE: 'builtin:speech/date',
      DEFAULT: 'builtin:grammar/none',
      LICENSE_PLATE: 'builtin:speech/spelling/license_plate',
      SPELLING_ALPHANUMERIC: 'builtin:speech/spelling/mixed', // Spelling mode for both letters and digits.
      SPELLING_DIGITS: 'builtin:speech/spelling/digits', // Spelling mode for digits.
      SPELLING_LETTERS: 'builtin:speech/spelling/letters', // Spelling mode fo letters.
      SPELLING_ALPHANUMERIC_PUNCT: 'builtin:speech/spelling/mixed_with_punct',
      SPELLING_LETTERS_PUNCT: 'builtin:speech/spelling/letters_with_punct',
      // mixed, digits and letters) accept additional options to narrow down the interpretation.
      // separator sep= + character or none
      // length= + integer
      // regex= + pattern
      TEXT2NUM: 'builtin:speech/text2num',
      // Instead of returning a plain text result, this grammar returns a structured document with different fields for street number, street, city, postal code and complement. It's quite robust.
      POSTAL_ADDRESS: 'builtin:speech/postal_address',
      ZIP_CODE: 'builtin:speech/spelling/zipcode'
    },
    MODE: {
      NORMAL: 'normal',
      HOT_WORD: 'hotword'
    },
    // https://docs.allo-media.net/stream-h2b/grammars/#available-grammars doesn't seem to have restriction so allow for all Allo Media languages
    BOOLEAN_GRAMMAR_LOCALES: ['fr_FR', 'en_US', 'en_GB', 'es_ES', 'de_DE']
  },
  DIALOGFLOW_SETTING_FIELDS: {
    CLIENT_EMAIL: 'client_email',
    CLIENT_ID: 'client_id',
    PRIVATE_KEY: 'private_key',
    PRIVATE_KEY_ID: 'private_key_id',
    PROJECT_ID: 'project_id'
  },
  LUIS_PORTAL_URL: 'https://www.luis.ai/applications/{appId}/versions/{version}/build/intents',
  AZURE_ARM_TOKEN_LINK: 'https://resources.azure.com/api/token?plaintext=true',
  ARM_TOKEN_MIN_LENGTH: 1000,
  TIMEOUT_LIMIT: {
    MIN: 0,
    MAX: 10000
  }
}

nlpConfig.RECOGNIZER_TIMEOUTS = {
  FORCE_DETECTION: {
    [nlpConfig.STT_SERVICES.AZURE]: 1000,
    [nlpConfig.STT_SERVICES.DIALOGFLOW]: 1500
  },
  IN_PROGRESS_EXTRA_TIME: 500
}

export default nlpConfig
